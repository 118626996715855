function loadGtagScript(ga4Id, callback) {
  // Check if gtag.js is already loaded
  if (document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=' + ga4Id + '"]')) {
    if (typeof callback === "function") callback();
    return;
  }

  // Load the gtag.js script
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + ga4Id;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag('js', new Date());
    gtag('config', ga4Id);

    if (typeof callback === "function") callback();
  };

  document.head.appendChild(script);
}

export default {
  install(Vue, options) {
    const { ga4Id, onReady } = options;
    const onReadyArr = !(Array.isArray(onReady)) ? onReady : [onReady]
    
    loadGtagScript(ga4Id, () => {
      // console.log('GA4 script loaded and configured.');
      onReadyArr.forEach(onReady => {
        if (typeof onReady === 'function') {
          onReady();
        } 
      });
    });

    Vue.prototype.$gtag = function (eventName, params) {
      if (window.gtag) {
        window.gtag('event', eventName, params);
      } else {
        onReadyArr.push(() => { window.gtag('event', eventName, params) })
        // console.warn('gtag.js is not ready yet.');
      }
    };
  },
};
